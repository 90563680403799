import React from 'react';
import tw from 'twin.macro';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useIntl } from 'gatsby-plugin-intl';
import { capitalizeFirstLetter } from '@utils/helpers';

import LayoutConcrete from '@layouts/concrete';
import SEO from '@components/seo.jsx';
import Text from '@components/text';
import BlockSanity from '@components/block-sanity';

const Container = tw.div`
  relative z-1 max-w-xs mx-auto px-10 pt-50 text-center 
  sm:max-w-480
  lg:max-w-full lg:mb-50 lg:px-0 lg:pt-0 lg:text-left
`;

const Title = tw(Text)`mb-15 lg:mb-44`;

const DefaultTemplate = ({ data }) => {
  const intl = useIntl();

  return (
    <LayoutConcrete
      switchPath={[
        `/strona/${data.sanityTemplate.slug_pl.current}`,
        `/en/page/${data.sanityTemplate.slug_en.current}`,
      ]}
    >
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'meta tags.title.homepage' })}
        description={intl.formatMessage({ id: 'meta tags.description.homepage' })}
      />
      <Container>
        <Title
          color="black"
          fontFamily="bebasNeue"
          fontSizes={[42, 56]}
          fontWeight={600}
          lineHeight={1.15}
          letterSpacing={[53, 70]}
        >
          {data.sanityTemplate[`title_${intl.locale}`]}
        </Title>
        <Text
          fontSizes={16}
          fontWeight={300}
          lineHeight={1.5}
          letterSpacing={20}
        >
          <BlockSanity content={data.sanityTemplate[`_rawContent${capitalizeFirstLetter(intl.locale)}`]} />
        </Text>
      </Container>
    </LayoutConcrete>
  );
};

export const query = graphql`
  query ($title: String) {
    sanityTemplate(title_pl: {eq: $title}) {
      title_pl
      title_en
      _rawContentPl
      _rawContentEn
      slug_en {
        current
      }
      slug_pl {
        current
      }
    }
  }
`;

DefaultTemplate.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any.isRequired,
};

export default DefaultTemplate;
